const DATA = {
  primary: "primary",
  Primary: "primary",
  secondary: "secondary",
  Secondary: "secondary",
  tertiary: "tertiary",
  Tertiary: "tertiary",
  quaternary: "quaternary",
  Quaternary: "quaternary",
  //
  blue: "primary",
  Blue: "primary",
  orange: "secondary",
  Orange: "secondary",
  navy: "tertiary",
  Navy: "tertiary",
  pink: "quaternary",
  Pink: "quaternary",
  sky_blue: "light",
  "sky blue": "light",
  "Sky Blue": "light",
  grey: "grey",
  Grey: "grey",
  //
  light: "light",
  Light: "light",
  "light blue": "light",
  "light-blue": "light",
  "light grey": "light-grey",
  "light-grey": "light-grey",
  dark: "dark",
  Dark: "dark",
  white: "white",
  White: "white",
  black: "black",
  Black: "black",
};

export const convertColorName = (name) => {
  if (name in DATA) {
    return DATA[name];
  }

  return name;
};
