import PropTypes from "prop-types";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { Button, Container, Stack } from "react-bootstrap";
import { Title } from "@/ui";
import { convertColorName } from "@/functions/convertColorName";
import { ensureTrailingSlash } from '@/functions/ensureTrailingSlash';

export default function TrendingJobs({ className, title, icon, items }) {
  return (
    <div className={clsx(className, classes.trending)}>
      <Container className="mw-xl text-center">
        <Stack className="flex-row justify-content-center gap-2 mb-5">
          {icon && (
            <div>
              <img src={icon} alt="" />
            </div>
          )}
          <Title title={title} className="mb-0" />
      </Stack>
        <Stack className="flex-row flex-wrap justify-content-center" gap={2}>
          {items.map((i, k) => (
            <Button
              key={k}
              className="mb-1 px-3"
              variant={`outline-${convertColorName(i.button_color) ?? "dark"}`}
              href={ensureTrailingSlash(i.url) ?? ""}
            >
              {i.title}
            </Button>
          ))}
        </Stack>
      </Container>
    </div>
  );
}

TrendingJobs.defaultProps = {
  className: "py-4 py-md-5",
  title: "Trending Jobs",
  items: [],
};

TrendingJobs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};
